import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSettings } from '../environments/environment';
import { EnvConfigService } from './env-config.service'

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor(private readonly translate: TranslateService,
    private readonly envService:EnvConfigService) {
  }

  private readonly loadingSubject = new BehaviorSubject<boolean>(true);
  loading$ = this.loadingSubject.asObservable();

  initializeApp(): Promise<any> {
    const getEnvData = this.envService.getEnvData();
    const defaultSelectedLang = getEnvData?.app?.language || LanguageSettings.defaultSelectedLang
    const language = sessionStorage.getItem('language') || defaultSelectedLang;
    this.translate.addLangs(LanguageSettings.languages);

    return new Promise((resolve, reject) => {
      this.translate.use(language).subscribe({
        next: () => {
          console.log('Translations loaded successfully.');
          resolve(true);
        },
        error: (error) => {
          console.error('Error loading translations:', error);
          resolve(false); 
        }
      });
    });
  }


  show() {
    this.loadingSubject.next(true);
  }

  hide() {
    this.loadingSubject.next(false);
  }
}
