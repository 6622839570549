import { Injectable } from '@angular/core';
import { NbDuplicateToastBehaviour, NbGlobalPhysicalPosition, NbToastrConfig, NbToastrService } from '@nebular/theme';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {


  private toastSubject = new Subject<string>();

  constructor(
    private toastrService: NbToastrService
  ) {}

  // Observable to listen for toast messages
  getToastMessage() {
    return this.toastSubject.asObservable();
  }

  // Function to trigger toast
  triggerToast(message: string): void {
    this.toastSubject.next(message); // Emit toast message
  }

  // Function to show the actual toast
  // showToast(type: any, message: string): void {
  //   console.log(type)
  //   // Map 'error' to 'danger', and ensure other types stay the same
  //   let tostrType = type || 'error'
  //   let errortype = tostrType === 'error' ? 'danger' : tostrType;
  //   console.log(type)
  //   this.toastrService.show(tostrType,message, {
  //     status: errortype || 'danger',  // Dynamically set the toast type
  //     duration: 2000,
  //   });
  // }


  // Helper function to get the icon based on the status
  private getIconForStatus(status: 'success' | 'danger' | 'info' | 'warning'): string {
    switch (status) {
      case 'success':
        return 'check-circle'; // Success icon
      case 'danger':
        return 'alert-circle'; // Danger icon
      case 'info':
        return 'info'; // Info icon
      case 'warning':
        return 'alert-triangle'; // Warning icon
      default:
        return ''; // Default case if no match
    }
  }


  // success(type: string = 'Success',message: string,action: string = 'Close', duration: number = 8000): void {
  //   this.snackBar.open(message, action, {
  //     duration: duration,
  //   });
  // }



  // success(type: string ,message: string, action: string = 'Close', config?: MatSnackBarConfig): void {
  //   let snackBarConfig: MatSnackBarConfig = {
  //     // duration: 3000, // default duration is 3 seconds
  //   // verticalPosition: 'top',
  //   horizontalPosition: 'center',
  //     ...config, // Allow overriding any config
  //   };
  //   console.log(type)
  //   // Add different styles based on the type
  //   let panelClass: string;
  //   switch (type) {
  //     case 'success':
  //       panelClass = 'success-snackbar';
  //       break;
  //     case 'error':
  //       panelClass = 'error-snackbar';
  //       break;
  //     case 'info':
  //     default:
  //       panelClass = 'info-snackbar';
  //       break;
  //   }

  //   snackBarConfig = { ...snackBarConfig, panelClass: [panelClass] };

  //   // Show the snack bar with the message and action
  //   this.snackBar.open(message, action, snackBarConfig);
  // }


  success(message: string): void {
    this.toastrService.show(message,'', {
      status: 'success',
      icon: 'checkmark-circle-outline', // Use Nebular Eva Icons
      duration: 3000,
      destroyByClick: true,
    });

  }

  // Method to show error toast
  danger(message: string, title: string = 'Error'): void {
    this.toastrService.show(message,'', {
      status: 'danger',
      icon: 'close-circle-outline', // Change to a different Eva icon
      duration: 3000,
      destroyByClick: true,
    });
  }
}

