import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { timer } from "rxjs";
import { FeaturesSettings } from "../../../environments/environment";
import { ApiService } from "../../admin/api.service";
import { EnvConfigService } from "../../env-config.service";

@Component({
  selector: "ngx-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  // providers: [AuthService],
})
export class LoginComponent implements OnInit {
  isdownload = FeaturesSettings.isdownload
  // Rider_App_Link = environment.rider_App_link;
  // Rider_Android_Link = environment.rider_Android_link;
  // Driver_App_Link = environment.driver_App_link;
  // Driver_Android_Link = environment.driver_Android_link
  isDemoMode = FeaturesSettings.isDemoMode;
  demoEmail = FeaturesSettings.demoEmail
  demoPassword = FeaturesSettings.demoPassword
  loginStatus: any;
  loginStatusMessage: any;
  loginForm = this.fb.group({
    email: [
      this.demoEmail,
      [
        Validators.required,
        Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
      ],
    ],
    password: [this.demoPassword, [Validators.required]],
  });
  getConfigValue: any;
  showMessages: any;
  errors: any;
  submitted: any;
  messages: any;
  user: any;
  rememberMe: any;
  socialLinks: any;
  form: any;
  activeTab: any = 'driver';
  item: any;
  link = "productLinks";
  constructor(
    public fb: FormBuilder,
    private readonly _service: ApiService,
    private readonly router: Router,
    private readonly envservice: EnvConfigService
  ) { }

  ngOnInit(): void {
    this.getLink()
  }

  login() {
    if (this.loginForm.valid) {
      // this._auth.login(this.loginForm.value);
      this._service
        .CommonLoginPostApi(this.loginForm.value, "common/login/admin")
        .subscribe({
          next: (res) => {
            if (res.type == "success") {
              console.log(res.type, "=========================")
              localStorage.setItem(
                "AdminDetails",
                JSON.stringify(res.data.admin)
              );
              localStorage.setItem("token", res.data.token);
              this.loginStatus = res.type;
              this.loginStatusMessage = res.message;
              timer(500).subscribe((res) => {
                // if(this.envservice.isInstallation ){
                  this.router.navigate(["/admin/dashboard"]);
                // }
                // else{
                //   this.router.navigate(['/installation'])
                // }
              });
            }
          },
          error: (error) => {
            this.loginStatus = error.error.type;
            this.loginStatusMessage = error.error.message;
          },
        });
    }
  }

  getLink() {
    this._service.CommonGetApi("common/configuration/public?required[]=" + this.link)
      .subscribe(data => {
        this.item = data.data
        console.log(this.item, "dataa")
      })
  }

  downloadsection(value: any) {
    this.activeTab = value
  }

  // playstore(event: Event) {
  //   event.preventDefault();
  //   const fullUrl = `${this.item?.productLinks?.androidPartner}`;
  //   console.log(fullUrl, "URLLL");
  //   window.open(fullUrl, "_blank");
  // }

  download(event: Event, platform) {
    event.preventDefault();
    const fullUrl = `${this.item?.productLinks?.[platform]}`;
    console.log(fullUrl, "URLLL");
    window.open(fullUrl, "_blank");
  }




}
