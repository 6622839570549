import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class EnvConfigService {
  private envConfig: any;
  private readonly renderer: Renderer2;
  private readonly fallbackFavicon: string = 'assets/images/favicon.png';
  isInstallation: boolean = true;

  constructor(private readonly http: HttpClient,
    private readonly rendererFactory : RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  loadConfig(): Observable<any> {
    return this.http.get<{ type: string; message: string; data: { data: any } }>(
      `${environment.API_ENDPOINT}common/configuration`
    ).pipe(
      tap((res) => {
        this.envConfig = res.data.data;
        this.isInstallation = true
        // this.isInstallation = this.envConfig.installation == 0 ? false : true
      }),
      catchError((error) => {
        console.error('Failed to load configuration:', error);
        this.envConfig = {};
        return of(null);
      })
    );
  }
  getInstallationStatus(): boolean {
    return this.isInstallation;
  }

  getEnvData() {
    return this.envConfig;
  }

  getImageBaseUrl(){
    return this.envConfig?.app?.baseurl
  }

  setFavicon(url: string): void {
    this.validateFavicon(url).then((isValid) => {
      const faviconUrl = isValid ? url : this.fallbackFavicon;
      console.log(faviconUrl, "favicon url");
      const link: HTMLLinkElement = this.getOrCreateFaviconElement();
      this.renderer.setAttribute(link, 'href', faviconUrl);
    });
  }

  private validateFavicon(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve(true);
      };
      img.onerror = () => {
        resolve(false);
      };
      img.src = url;
    });
  }



  private getOrCreateFaviconElement(): HTMLLinkElement {
    let link: HTMLLinkElement | null = document.querySelector('#favicon');
    if (!link) {
      link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'id', 'favicon');
      this.renderer.setAttribute(link, 'rel', 'icon');
      this.renderer.appendChild(document.head, link);
    }
    return link;
  }
}
