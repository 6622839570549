import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: "[google-map-autocomplete]",
})
export class GoogleMapAutocompleteDirective {

  // @Output() placeChanged = new EventEmitter<{ lat: number; lng: number; formatted_address: string }>();
  @Output() placeChanged = new EventEmitter<{ lat: number; lng: number; formatted_address: string }>();
  constructor(private readonly el: ElementRef) { }

  ngOnInit() {
    const input = this.el.nativeElement;
    const autocomplete = new google.maps.places.Autocomplete(input);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      
      if (place?.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const formatted_address = place.formatted_address; // Get the formatted address
        console.log(`Selected place: ${formatted_address}, Lat: ${lat}, Lng: ${lng}`);
        
        // Emit the event with lat, lng, and formatted_address
        this.placeChanged.emit({ lat, lng, formatted_address });
      } else {
        console.error('No place details available');
      }
    });
  }

}
