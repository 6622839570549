import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { InterceptorService } from './interceptor.service';
import { SmarttableService } from './admin/smarttable.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private readonly interceptorService: InterceptorService,     // Global loading management
    private readonly smarttableService: SmarttableService        // Specific table loading management
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Show global loading indicator
    this.interceptorService.show();

    // Show table-specific loading indicator (if needed)
    this.smarttableService.show();

    return next.handle(req).pipe(
      finalize(() => {
        // Hide both loading indicators
        this.interceptorService.hide();
        this.smarttableService.hide();
      })
    );
  }
}
