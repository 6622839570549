import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ApiService } from "./api.service";
import { ToastrService } from "../toastr.service";
@Injectable()
export class DataService {
  private readonly MenuData: Subject<any> = new Subject<any>();
  MenuData$: Observable<any> = this.MenuData.asObservable();
  colors: any;

  constructor(private apiService: ApiService,
    private toasterService: ToastrService
  ) { }

  private readonly rowData = new BehaviorSubject<any>({});
  private readonly driverData = new BehaviorSubject<any>({});
  private readonly menuData = new BehaviorSubject<any>({});
  private readonly Ng2SmartTableFilterOption = new BehaviorSubject<any>({});

  // private addGroupToggle = new BehaviorSubject<any>({});
  setNewRowInfo(data: any) {
    this.rowData.next(data);
  }


  getNewRowInfo() {
    console.log('Current Row Info:', this.rowData.getValue());
    return this.rowData.asObservable();
  }

  setMenuData(data: any) {
    this.menuData.next(data);
  }

  getMenuData() {
    return this.menuData.asObservable();
  }


  setDriverDataInfo(data: any) {
    this.driverData.next(data);
  }

  getDriverDataInfo() {
    return this.driverData.asObservable();
  }

  setNg2SmartTableFilterOption(data: any) {
    this.Ng2SmartTableFilterOption.next(data);
  }

  getNg2SmartTableFilterOption() {
    return this.Ng2SmartTableFilterOption.asObservable();
  }

  // menu_service




  setMenuInfo(updatedData) {
    this.MenuData.next(updatedData);
  }


  updateColor() {
    this.apiService.CommonGetApi("common/configuration/adminTheme").subscribe({
      next: (res) => {
        this.colors = res.data.data.admin
        Object.keys(this.colors).forEach(key => {
          const cssVar = this.getCssVarName(key);
          this.changeColor(this.colors[key], cssVar);
        });
      },
      error: (error) => {
        console.log(error)
        // this.toasterService.danger(error.error.message);
      },
    });

  }

  getCssVarName(key: string): string {
    const cssVarsMap = {
      header_bg: '--header-bg-color',
      header_text: '--header-color',
      heading_fs: '--text-header-size',
      menu_text: '--menu-color',
      menu_fs: '--menu-text-size',
      button_bg: '--button-color',
      theme_text: '--color-text',
      back_button: '--back-btn-color',
      body_fs: '--text-size',
      button_text: '--button-text',
      footer_text: '--footer-color',
      footer_bg: '--footer-bg-color'
    };
    return cssVarsMap[key] || '';
  }

  changeColor(value: string, cssVar: string): void {
    document.documentElement.style.setProperty(cssVar, value);
  }


}
