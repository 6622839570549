<div class="header-container">
  <div class="logo-container d-none d-xl-flex">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle ">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img #logoImage [src]="logoUrl" [attr.width]="logo_width" [attr.height]="logo_height" alt="logo icon" width="50px"
        (error)="logoImage.src = logo_url">
    </a>
  </div>
  <a class="logo d-xl-none d-block" href="#" (click)="navigateHome()">Rebustar<span>V3</span></a>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="control-item" icon="bell-outline" (click)="notificationroute()" (keydown)="handleKeyDown($event)">
    </nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.fname"
        [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
  <a (click)="toggleSidebar()" href="#" class="sidebar-toggle d-xl-none d-block">
    <nb-icon icon="menu-2-outline"></nb-icon>
  </a>
</div>